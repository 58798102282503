var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"duplicates-table"},[_c('div',{staticClass:"mb-2 text-2xl"},[_vm._v(" "+_vm._s(_vm.duplicateTitle)+" ")]),_c('div',{staticClass:"duplicate-table"},[_c('el-table',{ref:"leadTable",staticClass:"w-full border",attrs:{"border":true,"data":_vm.leadData,"default-sort":{ prop: 'groupes', order: 'ascending' },"stripe":true},on:{"sort-change":_vm.updateTableSorting,"selection-change":_vm.handleSelectionChange}},[_c('el-table-column',{attrs:{"type":"selection","width":"40","header-align":"center","align":"center"}}),_c('el-table-column',{attrs:{"label":_vm.$t('duplicates.clientName'),"sortable":true,"prop":"client_name","header-align":"center","min-width":"130"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('client-name',{attrs:{"lead":row.lead,"light":true}})]}}])}),_c('el-table-column',{attrs:{"label":_vm.$t('duplicates.groupes'),"sortable":true,"prop":"groupes","header-align":"center","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return _c('span',{staticClass:"label label-default w-full block text-base text-white rounded",style:({ 'background-color': _vm.customColors[row.groupes] })},[_vm._v(" "+_vm._s(row.groupes)+" ")])}}])}),_c('el-table-column',{attrs:{"label":_vm.$t('clientCard.phone'),"sortable":true,"prop":"phone","header-align":"center","align":"center","min-width":"105"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return _c('span',{domProps:{"innerHTML":_vm._s(row.phone)}})}}])}),_c('el-table-column',{attrs:{"label":_vm.$t('clientCard.email'),"sortable":true,"prop":"email","header-align":"center","align":"center","min-width":"210"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return _c('span',{domProps:{"innerHTML":_vm._s(row.email)}})}}])}),_c('el-table-column',{attrs:{"label":_vm.$t('clientCard.type'),"sortable":true,"prop":"type","header-align":"center","align":"center","min-width":"90"}}),_c('el-table-column',{attrs:{"label":_vm.$t('clientCard.advisor'),"sortable":true,"prop":"advisor","header-align":"center","align":"center"}}),_c('el-table-column',{attrs:{"label":_vm.$t('clientCard.division'),"sortable":true,"prop":"division","header-align":"center","align":"center"}}),_c('el-table-column',{attrs:{"label":_vm.$t('clientCard.status'),"sortable":true,"prop":"status","header-align":"center","align":"center"}}),_c('el-table-column',{attrs:{"label":_vm.$t('clientCard.date'),"sortable":true,"prop":"date","header-align":"center","min-width":"125","formatter":_vm.handleTableDateTimeFormat}}),_c('el-table-column',{attrs:{"label":_vm.$t('clientCard.vehicle'),"sortable":true,"prop":"vehicle","header-align":"center","min-width":"260","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return _c('span',{domProps:{"innerHTML":_vm._s(row.vehicle)}})}}])}),_c('el-table-column',{attrs:{"label":_vm.$t('clientCard.stock'),"sortable":true,"prop":"stock","header-align":"center","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return _c('span',{domProps:{"innerHTML":_vm._s(row.stock)}})}}])}),_c('el-table-column',{attrs:{"label":_vm.$t('clientCard.account'),"sortable":true,"prop":"account","header-align":"center","align":"center","min-width":"160"}}),_c('el-table-column',{attrs:{"label":_vm.$t('clientCard.sold'),"sortable":true,"prop":"sold","header-align":"center","align":"center"}}),_c('el-table-column',{attrs:{"label":_vm.$t('duplicates.delivered'),"sortable":true,"prop":"delivered","header-align":"center","align":"center"}}),_c('el-table-column',{attrs:{"label":_vm.$t('duplicates.offerNumber'),"sortable":true,"prop":"offer_number","align":"center","header-align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return _c('span',{domProps:{"innerHTML":_vm._s(row.offer_number)}})}}])}),_c('el-table-column',{attrs:{"label":_vm.$t('duplicates.orderNumber'),"sortable":true,"prop":"order_number","header-align":"center","align":"center","min-width":"120"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return _c('span',{domProps:{"innerHTML":_vm._s(row.order_number)}})}}])}),_c('el-table-column',{attrs:{"label":_vm.$t('clientCard.comment'),"sortable":true,"prop":"comment","header-align":"center","align":"center","min-width":"260"}}),_c('el-table-column',{attrs:{"label":_vm.$t('duplicates.source'),"sortable":true,"prop":"source","header-align":"center","align":"center"}}),_c('el-table-column',{attrs:{"label":_vm.$t('duplicates.action'),"header-align":"center","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('activix-tooltip',{attrs:{"content":_vm.$t('general.delete')}},[_c('icon',{staticClass:"link-grey | hover:text-red-500",attrs:{"name":_vm.$icons.trash},on:{"click":function($event){return _vm.onDeleteLead(row.id)}}})],1)]}}])})],1)],1),_c('el-dropdown',{attrs:{"trigger":"click","placement":"bottom-start"},on:{"command":_vm.onDropdownCommand}},[_c('activix-button',{staticClass:"mt-2",attrs:{"type":"primary"}},[_c('icon',{staticClass:"mr-2",attrs:{"name":"regular/check-square"}}),_c('span',[_vm._v(_vm._s(_vm.$t('massActions.title')))])],1),_c('el-dropdown-menu',{attrs:{"slot":"dropdown"},slot:"dropdown"},[_c('el-dropdown-item',{attrs:{"command":"associateLeads","disabled":_vm.bulkSelectLeads.length < 2}},[_c('div',{staticClass:"flex items-center"},[_c('icon',{staticClass:"mr-2 text-grey-500",attrs:{"name":"bold/multiple-neutral-2"}}),_vm._v(" "+_vm._s(_vm.$t('clientCard.associateLead'))+" ")],1)]),(_vm.authUser.hasCustomPermission('merge_lead') || _vm.authUser.isAdmin())?_c('el-dropdown-item',{attrs:{"command":"openActionDirectionModal","disabled":_vm.bulkSelectLeads.length != 2}},[_c('div',{staticClass:"flex items-center"},[_c('icon',{staticClass:"mr-2 text-grey-500",attrs:{"name":"regular/multiple-neutral-2"}}),_vm._v(" "+_vm._s(_vm.$t('clientCard.mergeLead'))+" ")],1)]):_vm._e()],1)],1),_c('action-direction',{attrs:{"opened":_vm.actionDirectionModalOpened},on:{"update:opened":function($event){_vm.actionDirectionModalOpened=$event}}}),_c('activix-confirm-modal',{attrs:{"type":"warning","content":_vm.$tc('client.deleteLeads.confirmation', 1),"opened":_vm.deleteModal.opened},on:{"update:opened":function($event){return _vm.$set(_vm.deleteModal, "opened", $event)},"approve":_vm.triggerDeleteLead,"closed":function($event){_vm.deleteModal.leadId = null}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }