<template>
    <div class="flex flex-col">
        <warning
            :icon="$icons.duplicate"
            :title="$t('error.warning')"
            :content="$t('error.selectAccountWarning')"
            v-if="!validAccount"
        />
        <template v-else>
            <div class="box" :class="{ loading: !accountId }">
                <div class="box-header | border-b">
                    <h4 class="box-title">
                        {{ $t('duplicates.search') }}
                    </h4>
                </div>
                <div class="box-body">
                    <duplicate-inputs
                        :inputs.sync="inputs"
                        :lead-types="leadTypes"
                        :divisions="divisions"
                        :matches="matches"
                        :date-types="dateTypes"
                    />

                    <div class="text-center">
                        <activix-button
                            type="primary"
                            :disabled="!isValid || loading"
                            :loading="loading"
                            @click="search"
                        >
                            {{ $t('duplicates.search') }}
                        </activix-button>
                    </div>
                </div>
            </div>

            <div class="box | mt-6" :class="{ loading: fade || !accountId }" v-if="!empty(resultLeads)">
                <div class="box-header | border-b">
                    <h4 class="box-title">
                        {{ $t('duplicates.results') }}
                    </h4>
                </div>
                <div class="box-body">
                    <div :key="key" v-for="(leads, key, index) in resultLeads">
                        <hr class="w-full" v-if="index > 0" />

                        <div class="pb-8" :class="[index == 0 ? 'pt-0' : 'pt-8']">
                            <duplicate-pagination-table
                                :leads="leads"
                                :title="key"
                                :group-by="inputs.groupBy"
                                @refresh="search"
                            />
                        </div>
                    </div>
                </div>
                <div class="box-footer">
                    <el-pagination
                        layout="total, ->, prev, pager, next"
                        :background="true"
                        :current-page.sync="pagination.currentPage"
                        :page-size="pagination.perPage"
                        :pager-count="5"
                        :total="pagination.total"
                        v-if="pagination.total > 0"
                    />
                </div>
            </div>

            <div class="box | mt-6" :class="{ loading: !accountId }" v-if="noResults">
                <div class="box-body">
                    <h3 class="text-center">
                        {{ $t('duplicates.noResult') }}
                    </h3>
                </div>
            </div>
        </template>
    </div>
</template>

<script>
    import { camelCase, get, isEmpty } from 'lodash-es';

    import { mapActions, mapState } from 'pinia';

    // Mixins
    import TrackView from '../mixins/TrackView.js';

    // Components
    import DuplicatePaginationTable from '../components/duplicates/DuplicatePaginationTable.vue';
    import DuplicateInputs from '../components/duplicates/DuplicateInputs.vue';
    import Warning from '../components/Warning.vue';

    // Entities
    import Division from '../entities/Division.js';

    // Pinia
    import { useContextStore } from '../store/modules/context/store.js';
    import { useGlobalStore } from '../store/store.js';

    export default {
        name: 'Duplicates',

        components: {
            DuplicatePaginationTable,
            DuplicateInputs,
            Warning,
        },

        mixins: [TrackView],

        data() {
            return {
                inputs: {
                    divisions: [],
                    dateTypes: [],
                    startDate: now_date()
                        .subDays(30)
                        .toString(),
                    endDate: now_date().toString(),
                    leadTypes: [],
                    groupBy: null,
                },
                pagination: {
                    currentPage: 1,
                    total: 0,
                    perPage: 5,
                },
                resultLeads: [],
                loading: false,
                fade: false,
                submittedSearch: false,
            };
        },

        computed: {
            ...mapState(useGlobalStore, ['authUser', 'configs']),
            ...mapState(useContextStore, {
                contextChildAccount: 'contextChildAccount',
                contextAccount: 'account',
            }),

            noResults() {
                return isEmpty(this.resultLeads) && this.submittedSearch && !this.loading;
            },

            dateTypes() {
                return [
                    {
                        value: 'created_at',
                        text: this.$t('date.createdDate'),
                    },
                    {
                        value: 'appointment_date',
                        text: this.$t('date.appointmentDate'),
                    },
                    {
                        value: 'presented_date',
                        text: this.$t('date.presentedDate'),
                    },
                    {
                        value: 'be_back_date',
                        text: this.$t('date.beBackDate'),
                    },
                    {
                        value: 'road_test_date',
                        text: this.$t('date.roadTestDate'),
                    },
                    {
                        value: 'take_over_date',
                        text: this.$t('date.takeOverDate'),
                    },
                    {
                        value: 'sale_date',
                        text: this.dashboardType == 'renewal' ? this.$t('date.renewalDate') : this.$t('date.saleDate'),
                    },
                    {
                        value: 'delivered_date',
                        text: this.$t('date.deliveredDate'),
                    },
                    {
                        value: 'delivery_date',
                        text: this.$t('date.deliveryDate'),
                    },
                    {
                        value: 'last_presented_date',
                        text: this.$t('date.lastPresentedDate'),
                    },
                    {
                        value: 'end_contract_date',
                        text: this.$t('date.endContractDate'),
                    },
                    {
                        value: 'call_date',
                        text: this.$t('date.callDate'),
                    },
                    {
                        value: 'updated_at',
                        text: this.$t('date.updatedAt'),
                    },
                ];
            },

            accountId() {
                return this.contextAccount.account_manager ? null : this.contextAccount.id;
            },

            validAccount() {
                return get(this.contextChildAccount, 'id');
            },

            isValid() {
                return (
                    as_locale(this.inputs.startDate, 'startDate').isValid() &&
                    as_locale(this.inputs.endDate, 'endDate').isValid() &&
                    !!this.inputs.groupBy &&
                    this.inputs.dateTypes &&
                    this.inputs.dateTypes.length > 0
                );
            },

            leadTypes() {
                const leadTypes = [];

                for (const leadType of this.configs.leadTypes) {
                    if (
                        leadType.name == 'pre_booking' ||
                        (!this.authUser.isAdmin() &&
                            ((leadType.name == 'loyalty' && !this.authUser.module_access.loyalty) ||
                                (leadType.name == 'renewal' && !this.authUser.module_access.renewal) ||
                                (leadType.name == 'web_order' && !this.authUser.hasWebOrder()))) ||
                        (leadType.name == 'sms' &&
                            !(
                                this.contextAccount.hasNioText() &&
                                (this.authUser.hasNioText() || this.authUser.isAdmin())
                            ))
                    ) {
                        continue;
                    }

                    leadTypes.push({
                        value: leadType.id,
                        text: this.$t(`leadTypes.${camelCase(leadType.name)}`),
                    });
                }

                // Sort
                leadTypes.sort((a, b) => a.text.localeCompare(b.text));

                return leadTypes;
            },

            divisions() {
                const filterDivisions = this.configs.divisions.filter(division => {
                    return division.id <= Division.SERVICE && this.authUser.hasDivisionAccess(division.id);
                });

                const divisions = [];

                divisions.push({
                    value: 0,
                    text: this.$t('divisions.none'),
                });

                for (const division of filterDivisions) {
                    divisions.push({
                        value: division.id,
                        text: this.$t(`divisions.${camelCase(division.name)}`),
                    });
                }

                return divisions;
            },

            matches() {
                return ['email', 'phone', 'name'].map(match => {
                    return {
                        value: match,
                        text: this.$t(`duplicates.${match}`),
                    };
                });
            },
        },

        watch: {
            'inputs.groupBy'(groupBy) {
                this.updateRouteQuery({ groupBy });
            },

            'inputs.startDate'(startDate) {
                this.updateRouteQuery({ startDate: as_locale(startDate, 'startDate').display('YYYY-MM-DD') });
            },

            'inputs.endDate'(endDate) {
                this.updateRouteQuery({ endDate: as_locale(endDate, 'endDate').display('YYYY-MM-DD') });
            },

            'inputs.leadTypes'(leadTypes) {
                this.updateRouteQuery({ leadTypes });
            },

            'inputs.divisions'(divisions) {
                this.updateRouteQuery({ divisions });
            },

            'inputs.dateTypes'(dateTypes) {
                this.updateRouteQuery({ dateTypes });
            },

            'pagination.currentPage'() {
                this.search();
            },

            accountId(accountId) {
                this.updateRouteQuery({ accountId });

                if (this.submittedSearch) {
                    if (this.isValid) {
                        this.search(true);
                    } else {
                        this.reset();
                    }
                }
            },
        },

        methods: {
            ...mapActions(useContextStore, ['setContextAccountAction']),

            applyRouteQuery() {
                const query = this.$route.query;

                if (query.accountId) {
                    this.setContextAccountAction(query.accountId);
                }

                if (query.startDate && as_locale(query.startDate, 'startDate').isValid()) {
                    this.inputs.startDate = query.startDate;
                }

                if (query.endDate && as_locale(query.endDate, 'endDate').isValid()) {
                    this.inputs.endDate = query.endDate;
                }

                if (query.groupBy) {
                    this.inputs.groupBy = query.groupBy;
                }

                if (query.leadTypes) {
                    if (query.leadTypes instanceof Array) {
                        this.inputs.leadTypes = query.leadTypes.filter(leadType => {
                            return this.leadTypes.find(t => t.value == leadType);
                        });
                    } else {
                        this.inputs.leadTypes = [query.leadTypes];
                    }
                }

                if (query.divisions) {
                    if (query.divisions instanceof Array) {
                        this.inputs.divisions = query.divisions.filter(division => {
                            return this.divisions.find(d => d.value == division);
                        });
                    } else {
                        this.inputs.divisions = [query.divisions];
                    }
                }

                if (query.dateTypes) {
                    if (query.dateTypes instanceof Array) {
                        this.inputs.dateTypes = query.dateTypes.filter(dateType => {
                            return this.dateTypes.find(d => d.value == dateType);
                        });
                    } else {
                        this.inputs.dateTypes = [query.dateTypes];
                    }
                }
            },

            updateRouteQuery(values) {
                this.$router.replace({
                    name: this.$route.name,
                    query: { ...this.$route.query, ...values },
                });
            },

            async search(reset = false) {
                if (!this.isValid) {
                    return;
                }

                this.loading = true;
                this.fade = true;

                try {
                    const response = await this.$axios.get('v1/leads/search-duplicates', {
                        params: {
                            startDate: this.inputs.startDate,
                            endDate: this.inputs.endDate,
                            leadTypes: this.inputs.leadTypes,
                            divisions: this.inputs.divisions,
                            groupBy: this.inputs.groupBy,
                            page: reset ? 1 : this.pagination.currentPage,
                            accountId: this.accountId,
                            dateTypes: this.inputs.dateTypes,
                        },
                    });

                    const data = response.data.data;

                    this.resultLeads = data.leads;
                    this.pagination.currentPage = data.pagination.current_page;
                    this.pagination.total = data.pagination.total;
                } catch (e) {
                    // @TODO Handle
                }

                this.loading = false;
                this.fade = false;
                this.submittedSearch = true;
            },

            reset() {
                this.submittedSearch = false;
                this.resultLeads = [];
                this.currentPage = 1;
            },

            onLeadMerged() {
                this.$notify.success(this.$t('duplicates.mergedSuccessfully'));
            },
        },

        created() {
            this.applyRouteQuery();
        },

        mounted() {
            this.$eventBus.$on('leads-merged', this.onLeadMerged);
        },

        beforeDestroy() {
            this.$eventBus.$off('leads-merged', this.onLeadMerged);
        },
    };
</script>
