<template>
    <div class="flex flex-wrap -mx-3">
        <div class="w-full xl:w-1/6 px-3">
            <div class="form-group">
                <label> {{ $t('duplicates.matchBy') }} <span class="form-star">*</span> </label>
                <activix-multiselect
                    identifier="value"
                    label="text"
                    :multiple="false"
                    :options="matches"
                    :selected="selectedGroupBy"
                    :searchable="false"
                    :allow-empty="false"
                    :close-on-select="true"
                    @update="updateGroupBy"
                />
            </div>
        </div>
        <div class="w-full xl:w-1/6 px-3">
            <div class="form-group">
                <label>
                    {{ $t('duplicates.leadType') }}
                </label>
                <activix-multiselect
                    identifier="value"
                    label="text"
                    multiple="multiple"
                    :options="leadTypes"
                    :selected="selectedLeadTypes"
                    :searchable="false"
                    :allow-empty="true"
                    :close-on-select="false"
                    @update="updateLeadTypes"
                />
            </div>
        </div>
        <div class="w-full xl:w-1/6 px-3">
            <div class="form-group">
                <label>
                    {{ $t('duplicates.division') }}
                </label>
                <activix-multiselect
                    identifier="value"
                    label="text"
                    multiple="multiple"
                    :options="divisions"
                    :selected="selectedDivisions"
                    :searchable="false"
                    :allow-empty="true"
                    :close-on-select="false"
                    @update="updateDivisions"
                />
            </div>
        </div>
        <div class="w-full xl:w-1/6 px-3">
            <div class="form-group">
                <activix-tooltip :content="$t('duplicates.dateTypeTooltip')">
                    <div>
                        <label> {{ $t('duplicates.dateType') }} <span class="form-star">*</span> </label>
                        <activix-multiselect
                            identifier="value"
                            label="text"
                            multiple="multiple"
                            :options="dateTypes"
                            :selected="selectedDateTypes"
                            :searchable="false"
                            :allow-empty="false"
                            :close-on-select="false"
                            :clearable="true"
                            @update="updateDateTypes"
                        />
                    </div>
                </activix-tooltip>
            </div>
        </div>
        <div class="w-full xl:w-1/6 px-3">
            <div class="form-group">
                <label> {{ $t('duplicates.startDate') }} <span class="form-star">*</span> </label>
                <date-time-picker :date-only="true" :value="inputs.startDate" @input="updateStartDate" />
            </div>
        </div>
        <div class="w-full xl:w-1/6 px-3">
            <div class="form-group">
                <label> {{ $t('duplicates.endDate') }} <span class="form-star">*</span> </label>
                <date-time-picker :date-only="true" :value="inputs.endDate" @input="updateEndDate" />
            </div>
        </div>
    </div>
</template>

<script>
    // Components
    import DateTimePicker from '../inputs/DateTimePicker.vue';

    export default {
        components: {
            DateTimePicker,
        },

        props: {
            inputs: {
                type: Object,
                default: () => ({}),
            },
            leadTypes: {
                type: Array,
                default: () => [],
            },
            divisions: {
                type: Array,
                default: () => [],
            },
            matches: {
                type: Array,
                default: () => [],
            },
            dateTypes: {
                type: Array,
                default: () => [],
            },
        },

        computed: {
            selectedGroupBy() {
                return this.matches.find(m => m.value == this.inputs.groupBy);
            },

            selectedLeadTypes() {
                return this.leadTypes.filter(leadType => {
                    return this.inputs.leadTypes.find(t => t == leadType.value);
                });
            },

            selectedDivisions() {
                return this.divisions.filter(division => {
                    return this.inputs.divisions.find(d => d == division.value) != undefined;
                });
            },

            selectedDateTypes() {
                return this.dateTypes.filter(dateType => {
                    return this.inputs.dateTypes.find(d => d == dateType.value) != undefined;
                });
            },
        },

        methods: {
            updateLeadTypes(leadTypes) {
                this.updateInputs({
                    leadTypes: leadTypes.filter(leadType => leadType.value != 'all').map(leadType => leadType.value),
                });
            },

            updateDivisions(divisions) {
                this.updateInputs({
                    divisions: divisions.filter(division => division.value != 'all').map(division => division.value),
                });
            },

            updateGroupBy(match) {
                this.updateInputs({ groupBy: match.value || null });
            },

            updateStartDate(startDate) {
                this.updateInputs({ startDate });
            },

            updateEndDate(endDate) {
                this.updateInputs({ endDate });
            },

            updateDateTypes(dates) {
                this.updateInputs({
                    dateTypes: dates.filter(date => date.value != 'all').map(date => date.value),
                });
            },

            updateInputs(values) {
                if (values) {
                    this.$emit('update:inputs', { ...this.inputs, ...values });
                }
            },
        },
    };
</script>
