<template>
    <div class="duplicates-table">
        <div class="mb-2 text-2xl">
            {{ duplicateTitle }}
        </div>

        <div class="duplicate-table">
            <el-table
                class="w-full border"
                :border="true"
                :data="leadData"
                :default-sort="{ prop: 'groupes', order: 'ascending' }"
                :stripe="true"
                ref="leadTable"
                @sort-change="updateTableSorting"
                @selection-change="handleSelectionChange"
            >
                <el-table-column
                    type="selection"
                    width="40"
                    header-align="center"
                    align="center"
                />
                <el-table-column
                    :label="$t('duplicates.clientName')"
                    :sortable="true"
                    prop="client_name"
                    header-align="center"
                    min-width="130"
                >
                    <template slot-scope="{ row }">
                        <client-name :lead="row.lead" :light="true" />
                    </template>
                </el-table-column>
                <el-table-column
                    :label="$t('duplicates.groupes')"
                    :sortable="true"
                    prop="groupes"
                    header-align="center"
                    align="center"
                >
                    <span
                        class="label label-default w-full block text-base text-white rounded"
                        :style="{ 'background-color': customColors[row.groupes] }"
                        slot-scope="{ row }"
                    >
                        {{ row.groupes }}
                    </span>
                </el-table-column>
                <el-table-column
                    :label="$t('clientCard.phone')"
                    :sortable="true"
                    prop="phone"
                    header-align="center"
                    align="center"
                    min-width="105"
                >
                    <span slot-scope="{ row }" v-html="row.phone" />
                </el-table-column>
                <el-table-column
                    :label="$t('clientCard.email')"
                    :sortable="true"
                    prop="email"
                    header-align="center"
                    align="center"
                    min-width="210"
                >
                    <span slot-scope="{ row }" v-html="row.email" />
                </el-table-column>
                <el-table-column
                    :label="$t('clientCard.type')"
                    :sortable="true"
                    prop="type"
                    header-align="center"
                    align="center"
                    min-width="90"
                />
                <el-table-column
                    :label="$t('clientCard.advisor')"
                    :sortable="true"
                    prop="advisor"
                    header-align="center"
                    align="center"
                />
                <el-table-column
                    :label="$t('clientCard.division')"
                    :sortable="true"
                    prop="division"
                    header-align="center"
                    align="center"
                />
                <el-table-column
                    :label="$t('clientCard.status')"
                    :sortable="true"
                    prop="status"
                    header-align="center"
                    align="center"
                />
                <el-table-column
                    :label="$t('clientCard.date')"
                    :sortable="true"
                    prop="date"
                    header-align="center"
                    min-width="125"
                    :formatter="handleTableDateTimeFormat"
                />
                <el-table-column
                    :label="$t('clientCard.vehicle')"
                    :sortable="true"
                    prop="vehicle"
                    header-align="center"
                    min-width="260"
                    align="center"
                >
                    <span slot-scope="{ row }" v-html="row.vehicle" />
                </el-table-column>
                <el-table-column
                    :label="$t('clientCard.stock')"
                    :sortable="true"
                    prop="stock"
                    header-align="center"
                    align="center"
                >
                    <span slot-scope="{ row }" v-html="row.stock" />
                </el-table-column>
                <el-table-column
                    :label="$t('clientCard.account')"
                    :sortable="true"
                    prop="account"
                    header-align="center"
                    align="center"
                    min-width="160"
                />
                <el-table-column
                    :label="$t('clientCard.sold')"
                    :sortable="true"
                    prop="sold"
                    header-align="center"
                    align="center"
                />
                <el-table-column
                    :label="$t('duplicates.delivered')"
                    :sortable="true"
                    prop="delivered"
                    header-align="center"
                    align="center"
                />
                <el-table-column
                    :label="$t('duplicates.offerNumber')"
                    :sortable="true"
                    prop="offer_number"
                    align="center"
                    header-align="center"
                >
                    <span slot-scope="{ row }" v-html="row.offer_number" />
                </el-table-column>
                <el-table-column
                    :label="$t('duplicates.orderNumber')"
                    :sortable="true"
                    prop="order_number"
                    header-align="center"
                    align="center"
                    min-width="120"
                >
                    <span slot-scope="{ row }" v-html="row.order_number" />
                </el-table-column>
                <el-table-column
                    :label="$t('clientCard.comment')"
                    :sortable="true"
                    prop="comment"
                    header-align="center"
                    align="center"
                    min-width="260"
                />
                <el-table-column
                    :label="$t('duplicates.source')"
                    :sortable="true"
                    prop="source"
                    header-align="center"
                    align="center"
                />
                <el-table-column :label="$t('duplicates.action')" header-align="center" align="center">
                    <template slot-scope="{ row }">
                        <activix-tooltip :content="$t('general.delete')">
                            <icon
                                class="link-grey | hover:text-red-500"
                                :name="$icons.trash"
                                @click="onDeleteLead(row.id)"
                            />
                        </activix-tooltip>
                    </template>
                </el-table-column>
            </el-table>
        </div>

        <el-dropdown trigger="click" placement="bottom-start" @command="onDropdownCommand">
            <activix-button type="primary" class="mt-2">
                <icon name="regular/check-square" class="mr-2" />
                <span>{{ $t('massActions.title') }}</span>
            </activix-button>

            <el-dropdown-menu slot="dropdown">
                <el-dropdown-item command="associateLeads" :disabled="bulkSelectLeads.length < 2">
                    <div class="flex items-center">
                        <icon class="mr-2 text-grey-500" name="bold/multiple-neutral-2" />
                        {{ $t('clientCard.associateLead') }}
                    </div>
                </el-dropdown-item>
                <el-dropdown-item
                    command="openActionDirectionModal"
                    :disabled="bulkSelectLeads.length != 2"
                    v-if="authUser.hasCustomPermission('merge_lead') || authUser.isAdmin()"
                >
                    <div class="flex items-center">
                        <icon class="mr-2 text-grey-500" name="regular/multiple-neutral-2" />
                        {{ $t('clientCard.mergeLead') }}
                    </div>
                </el-dropdown-item>
            </el-dropdown-menu>
        </el-dropdown>

        <action-direction :opened.sync="actionDirectionModalOpened" />

        <activix-confirm-modal
            type="warning"
            :content="$tc('client.deleteLeads.confirmation', 1)"
            :opened.sync="deleteModal.opened"
            @approve="triggerDeleteLead"
            @closed="deleteModal.leadId = null"
        />
    </div>
</template>

<script>
    /* eslint-disable vue/no-mutating-props */
    import { sortBy, isEmpty, camelCase, get } from 'lodash-es';

    import { mapActions, mapState } from 'pinia';

    import ActionDirection from '../modals/ActionDirection.vue';
    import ClientName from '../dashboards/columns/ClientName.vue';

    import DataTableMixin from '../../mixins/DataTable.js';

    import Lead from '../../entities/Lead.js';

    import { showError } from '../../utils/toastr.js';
    import { useDashboardStore } from '../../store/modules/dashboard/store.js';
    import { useContextStore } from '../../store/modules/context/store.js';
    import { useGlobalStore } from '../../store/store.js';

    export default {
        components: {
            ActionDirection,
            ClientName,
        },

        mixins: [DataTableMixin],

        props: {
            leads: {
                type: Array,
                default: () => [],
            },
            title: {
                type: String,
                default: '',
            },
            groupBy: {
                type: String,
                default: '',
            },
        },

        data() {
            return {
                bulkSelectLeads: [],
                customColors: {
                    A: '#607D8B',
                    B: '#4A148C',
                    C: '#01579B',
                    D: '#F44336',
                    E: '#E91E63',
                    F: '#33691E',
                    G: '#9C27B0',
                    H: '#673AB7',
                    I: '#8D6E63',
                    J: '#3F51B5',
                    K: '#2196F3',
                    L: '#03A9F4',
                    M: '#BF360C',
                    N: '#00BCD4',
                    O: '#009688',
                    P: '#4CAF50',
                    Q: '#8BC34A',
                    R: '#E65100',
                    S: '#CDDC39',
                    T: '#FFEB3B',
                    U: '#FFC107',
                    V: '#FF9800',
                    W: '#FF5722',
                    X: '#795548',
                    Y: '#9A9A9A',
                    Z: '#607D8B',
                },
                actionDirectionModalOpened: false,
                deleteModal: {
                    opened: false,
                    leadId: null,
                },
            };
        },

        computed: {
            ...mapState(useGlobalStore, ['mergedLead', 'configs', 'authUser']),
            ...mapState(useContextStore, ['contextLead']),
            ...mapState(useDashboardStore, ['division', 'blackListedLeadIds', 'allLeadsInTableSelected']),

            sortedLeads() {
                const sortedLeads = sortBy(this.leads, 'customer_id');
                let lastIndexGroup = -1;
                let lastCustomerId = null;

                for (let i = 0; i < sortedLeads.length; ++i) {
                    if (lastCustomerId != sortedLeads[i].customer_id && sortedLeads[i].associated_leads_count > 0) {
                        lastIndexGroup++;
                        sortedLeads[i].group_id = this.alphabet[lastIndexGroup];
                        lastCustomerId = sortedLeads[i].customer_id;
                    } else if (sortedLeads[i].customer_id && sortedLeads[i].associated_leads_count > 0) {
                        sortedLeads[i].group_id = this.alphabet[lastIndexGroup];
                    }
                }

                return sortedLeads;
            },

            leadData() {
                return this.sortedLeads.map(row => {
                    return this.mappedData(row);
                });
            },

            alphabet() {
                const alphabet = [];
                let i = 65;
                const j = 90;

                for (; i <= j; ++i) {
                    alphabet.push(String.fromCharCode(i));
                }

                return alphabet;
            },

            duplicateTitle() {
                if (this.groupBy == 'email' || this.groupBy == 'phone') {
                    return this.title;
                }

                return this.getFullName(this.sortedLeads[0]);
            },
        },

        methods: {
            ...mapActions(useContextStore, ['setContextLeadIdAction']),
            ...mapActions(useGlobalStore, ['setMergedLeadIdAction']),

            getAssociatedLeadCountTootip(row) {
                return this.$tc('duplicates.associatedLeads', row.associatedLeadsCount, [row.associatedLeadsCount]);
            },

            mappedData(row) {
                const lead = new Lead(row.lead ? row.lead : row);
                const comment = get(lead.latest_comment, 'content', '');

                const offerNumber = lead
                    .getVehiclesField('offer')
                    .replace(/<br\/?>/, '')
                    .trim();
                const orderNumber = lead
                    .getVehiclesField('order')
                    .replace(/<br\/?>/, '')
                    .trim();
                const stock = lead
                    .getVehiclesField('stock')
                    .replace(/<br\/?>/, '')
                    .trim();
                const vehicle = lead
                    .getVehiclesString()
                    .replace(/<br\/?>/, '')
                    .trim();
                const advisor = lead
                    .getUserName()
                    .replace(/<br\/?>/, '')
                    .trim();

                return {
                    id: lead.id,
                    lead,
                    associatedLeadsCount: row.associated_leads_count,
                    customer_id: lead.customer_id,
                    client_name: lead.fullName || '-',
                    groupes: row.group_id ? row.group_id : '-',
                    phone: lead.getPhonesString() || '-',
                    email: lead.getEmailsString() || '-',
                    type: this.getLeadTypeName(lead.lead_type_id),
                    advisor: !isEmpty(advisor) ? advisor : '-',
                    division: this.getDivisionName(lead.division_id),
                    status: this.getLeadStatus(lead),
                    date: as_locale(lead.created_at, 'created_at'),
                    vehicle: !isEmpty(vehicle) ? vehicle : '-',
                    offer_number: !isEmpty(offerNumber) ? offerNumber : '-',
                    order_number: !isEmpty(orderNumber) ? orderNumber : '-',
                    stock: !isEmpty(stock) ? stock : '-',
                    account: lead.getAccountName() || '-',
                    sold: this.$t(`duplicates.boolean.${lead.sale}`),
                    delivered: this.$t(`duplicates.boolean.${lead.delivered}`),
                    comment: comment.length > 25 ? `${comment.slice(0, 25)} ...` : comment,
                    source: lead.source ? lead.source.name : '-',
                    action: '',
                };
            },

            associateLeads() {
                if (this.bulkSelectLeads.length < 2) {
                    return;
                }

                const [masterLead, associatedLeadIds] = this.bulkSelectLeads;

                const query = (() => {
                    if (this.allLeadsInTableSelected) {
                        return { search: this.dashboardCriteria, blackListedLeadIds: this.blackListedLeadIds };
                    }

                    return { id: masterLead };
                })();

                this.$api.leads
                    .associate(query, {
                        lang: this.$i18n.locale,
                        masterLead,
                        associatedIds: associatedLeadIds,
                        dissociation: false,
                    })
                    .then(() => {
                        const selectedLeadIds = this.bulkSelectLeads;
                        const withArray = [
                            'leadVehicles:id,lead_id,make,model,year,order_number,offer,sold,stock',
                            'account:id,name',
                            'latestComment:id,lead_id,content',
                            'leadEmails:id,lead_id,email',
                            'leadPhones:id,lead_id,phone',
                            'user:first_name,last_name',
                            'source:id,name',
                        ];

                        const selectArray = [
                            'id',
                            'name_slug',
                            'account_id',
                            'user_id',
                            'status',
                            'division_id',
                            'created_at',
                            'lead_type_id',
                            'first_name',
                            'last_name',
                            'status',
                            'created_by_user',
                            'import_id',
                            'renewal_id',
                            'customer_id',
                            'sale',
                            'delivered',
                            'latest_comment_id',
                        ];
                        const nullArray = ['merged_id'];

                        this.$refs.leadTable.clearSelection();

                        this.$notify.success(this.$t('duplicates.associatedSuccessfully'));

                        // @TODO Why is there a setTimeout? Split in another method... It's getting way too long!
                        setTimeout(() => {
                            this.$axios
                                .get('v1/leads/fetch', {
                                    params: {
                                        ids: selectedLeadIds,
                                        withArray,
                                        nullArray,
                                        selectArray,
                                    },
                                })
                                .then(response => {
                                    const refreshedLeads = selectedLeadIds.map(id => {
                                        return {
                                            currentIndex: this.getLeadPosition(id),
                                            leadId: id,
                                            updated: false,
                                        };
                                    });

                                    // Update leads
                                    response.data.data.leads.forEach(lead => {
                                        refreshedLeads.forEach(refreshedLead => {
                                            if (refreshedLead.leadId === lead.id) {
                                                let currentCount = this.leads[refreshedLead.currentIndex]
                                                    .associated_leads_count;
                                                this.leads[
                                                    refreshedLead.currentIndex
                                                ].associated_leads_count = ++currentCount;
                                                this.leads[refreshedLead.currentIndex].lead = lead;
                                                this.leads[refreshedLead.currentIndex].customer_id = lead.customer_id;
                                                refreshedLead.updated = true;
                                            }
                                        });
                                    });

                                    // Delete leads
                                    refreshedLeads.forEach(refreshedLead => {
                                        if (!refreshedLead.updated) {
                                            this.leads.splice(refreshedLead.currentIndex, 1);
                                        }
                                    });
                                });
                        }, 500);
                    })
                    .catch(error => {
                        if (!error || !error.response) {
                            showError();
                            return;
                        }

                        showError();
                    });
            },

            openActionDirectionModal() {
                this.setContextLeadIdAction(this.bulkSelectLeads[0]);
                this.setMergedLeadIdAction(this.bulkSelectLeads[1]);

                this.actionDirectionModalOpened = true;
            },

            getDivisionName(divisionId) {
                for (const division of this.configs.divisions) {
                    if (division.id === divisionId) {
                        return this.$t(`divisions.${division.name}`);
                    }
                }

                return '';
            },

            getLeadTypeName(leadTypeId) {
                for (const leadType of this.configs.leadTypes) {
                    if (leadType.id === leadTypeId) {
                        return this.$t(`leadTypes.${camelCase(leadType.name)}`);
                    }
                }

                return '';
            },

            getLeadStatus(lead) {
                if (lead.status == 'lost') {
                    return this.$t('clientCard.lost');
                }

                if (lead.lead_type_id != 5) {
                    if (lead.status == 'duplicate') {
                        return this.$t('clientCard.duplicate');
                    }

                    if (lead.status == 'invalid') {
                        return this.$t('clientCard.invalid');
                    }
                }

                return '-';
            },

            onDeleteLead(id) {
                this.deleteModal.leadId = id;
                this.deleteModal.opened = true;
            },

            async triggerDeleteLead() {
                try {
                    await this.$api.leads.delete({ id: this.deleteModal.leadId });

                    this.$notify.success(this.$t('duplicates.leadDeletedSuccessfully'));

                    this.$emit('refresh');
                    this.$refs.leadTable.clearSelection();
                } catch (e) {
                    this.$notify.error(this.$t('client.deleteLeads.failedSingle'));
                }
            },

            getLeadPosition(id) {
                for (let i = 0; i < this.leads.length; i++) {
                    const lead = this.leads[i].lead ? this.leads[i].lead : this.leads[i];

                    if (lead.id == id) {
                        return i;
                    }
                }

                return -1;
            },

            handleSelectionChange(selection) {
                if (selection.length < 1) {
                    this.bulkSelectLeads = [];
                    return;
                }

                this.bulkSelectLeads = selection.map(lead => lead.id);
            },

            onLeadMerged() {
                // Check if the lead exists, if yes update
                const refreshedLeads = [];

                if (this.getLeadPosition(payload.from.id) != -1) {
                    refreshedLeads.push({
                        currentIndex: this.getLeadPosition(payload.from.id),
                        leadId: payload.from.id,
                        updated: false,
                    });
                }

                if (this.getLeadPosition(payload.to.id) != -1) {
                    refreshedLeads.push({
                        currentIndex: this.getLeadPosition(payload.to.id),
                        leadId: payload.to.id,
                        updated: false,
                    });
                }

                // No leads found in the table return
                if (refreshedLeads.length == 0) {
                    return;
                }

                setTimeout(() => {
                    const withArray = [
                        'leadVehicles:id,lead_id,make,model,year,order_number,offer,sold,stock',
                        'account:id,name',
                        'latestComment:id,lead_id,content',
                        'leadEmails:id,lead_id,email',
                        'leadPhones:id,lead_id,phone',
                        'user:first_name,last_name',
                        'source:id,name',
                    ];

                    const selectArray = [
                        'id',
                        'name_slug',
                        'account_id',
                        'user_id',
                        'status',
                        'division_id',
                        'created_at',
                        'lead_type_id',
                        'first_name',
                        'last_name',
                        'status',
                        'created_by_user',
                        'import_id',
                        'renewal_id',
                        'customer_id',
                        'sale',
                        'delivered',
                        'latest_comment_id',
                    ];
                    const nullArray = ['merged_id'];

                    this.$axios
                        .get('v1/leads/fetch', {
                            params: {
                                ids: [payload.to.id, payload.from.id],
                                withArray,
                                selectArray,
                                nullArray,
                            },
                        })
                        .then(response => {
                            this.$refs.leadTable.clearSelection();

                            // Update leads
                            response.data.data.leads.forEach(lead => {
                                refreshedLeads.forEach(refreshedLead => {
                                    if (refreshedLead.leadId == lead.id) {
                                        this.leads[refreshedLead.currentIndex].lead = lead;
                                        refreshedLead.updated = true;
                                    }
                                });
                            });

                            // Delete leads
                            refreshedLeads.forEach(refreshedLead => {
                                if (!refreshedLead.updated) {
                                    this.leads.splice(refreshedLead.currentIndex, 1);
                                }
                            });
                        });
                }, 500);
            },

            onDropdownCommand(command) {
                if (command) {
                    this[command]();
                }
            },
        },

        mounted() {
            this.$eventBus.$on('leads-merged', this.onLeadMerged);
        },

        beforeDestroy() {
            this.$eventBus.$off('leads-merged', this.onLeadMerged);
        },
    };
</script>
